import React from "react";
import { Badge, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import LeftFilters from "./LeftFilters";
import RightFilters from "./RightFilters";
import DownloadAuditReport from "../../../Client/detail/components/Units/DownloadAuditReport";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { downloadAuditReport } from "../../../../sevices/leadComplianceApi";
import PrimaryButton from "../../../../components/Button/Button";
import { useSelector } from "react-redux";

function Filters({ building, client }) {
  const statusCount = useSelector((state) => state.compliance.count);
  return (
    <div className="tickets-filter d-flex align-items-center justify-content-space-between">
      <LeftFilters />
      {/* <RightFilters /> */}
      <Stack spacing={0.5} direction="row">
        <PrimaryButton
          variant="contained"
          className={`capitalize`}
          disabled={true}
        >
          Returned Notices
          <Chip
            label={
              (statusCount?.completed ?? 0) + (statusCount?.in_process ?? 0)
            }
            size="small"
            color="success"
            sx={{ backgroundColor: "#11b50c66 !important" }}
            className="ml-1"
          />
        </PrimaryButton>
        <DownloadAuditReport
          building={building}
          client={client}
          downloadAuditReportPdf={downloadAuditReport}
          reportId={"mailerPdfId"}
        />
      </Stack>
    </div>
  );
}

export default Filters;
