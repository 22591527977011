import React, { useState } from "react";
import toast from "react-hot-toast";

import LongFormDialog from "../../../components/Dialog/LongFormDialog";
import SurveyIcon from "../../../styles/svg/survey-download.svg";
import ViewFile from "../../../components/ViewFile";
import { downloadFireSurvey } from "../../../sevices/fireNoticeApi";
import DownloadFireSurvey from './DownloadFireSurvey';
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";

function ViewSurvey({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const [url, setUrl] = useState("");
  const [year] = useCustomSearchParams("year");

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  const downloadMailer = () => {
    // setLoading(true);
    downloadFireSurvey({
      year: year,
      fire_notice_id: params.row?.fire_notice?.fire_notice_id,
    })
      .then((res) => {
        setUrl({
          url: `/pdf/${res.data.pdf_file_path}`,
        });
        handleClick();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Survey Response"}
          btnTitle={"Confirm"}
          IsChildren={
            <DownloadFireSurvey
              surveyId={params.row?.fire_notice?.fire_notice_id}
            />
          }
        >
          <ViewFile url={url.url} />
        </LongFormDialog>
      )}
      <div className="w-100 d-flex justify-content-center">
        {params.row?.fire_notice?.status != "pending" &&
          params.row?.fire_notice?.status && (
            <img
              src={SurveyIcon}
              onClick={downloadMailer}
              className="cursor-pointer"
            />
          )}
      </div>
    </>
  );
}

export default ViewSurvey;
