import React, { useImperativeHandle, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Stack,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import useCustomForm from "../../../hooks/useForm";
import useCustomMutate from "../../../hooks/useMutate";
import Switch from "../../services/components/Switch";
import { deFormatPhoneNumber, formatPhoneNumber } from "../../../utils/helpers";
import { createClient, updateClient } from "../../../sevices/clientApi";
import PendingIcon from "../../../styles/svg/pending-icon.svg";
import ActiveIcon from "../../../styles/svg/active-icon.svg";
import SchedulingIcon from "../../../styles/svg/scheduling-blocked-icon.svg";
import AccountBlockedIcon from "../../../styles/svg/account-blocked-icon.svg";
import deleteAccount from "../../../styles/svg/delete-client.svg";
import ToggleInput from "../../../components/ToggleInput";
import StatusDropDown from "./StatusDropDown";

function ClientForm({ initialValues, onClose, isEdit, reference }) {
  const [loading, setLoading] = useState(false);
  useImperativeHandle(reference, () => ({
    submitForm() {
      handleSubmit(submit)();
    },
  }));
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleReset,
    setValue,
    watch,
    setError,
    clearErrors,
    isValid,
  } = useCustomForm(initialValues);
  const { mutate } = useCustomMutate(
    isEdit ? updateClient : createClient,
    ["clients", "client-detail"],
    () => {
      onClose();
    },
    () => {
      setLoading(false);
    }
  );

  const password = watch("password");

  const fields = [
    {
      name: "name",
      label: "Full Name",
      type: "text",

      placeholder: "Enter name",
    },
    {
      name: "position",
      label: "Position",
      type: "text",

      placeholder: "Enter position",
    },
    {
      name: "phone_number",
      label: "Phone #",
      type: "number",

      placeholder: "Enter phone number",
    },
    {
      name: "email",
      label: "Email Address",
      type: "text",

      placeholder: "Enter Email",
    },

    {
      name: "password",
      label: isEdit ? "Change Password" : "Login Password",
      type: "password",

      placeholder: isEdit ? "Enter Change Password" : "Enter Password",
    },
    {
      name: "confirm_password",
      label: "Confirm Password",
      type: "password",

      placeholder: "Enter Password",
    },
  ];

  const pricingField = [
    {
      name: "xrf_single_unit_testing_rate",
      label: "XRF Single Unit Testing Rate",
      type: "number",

      placeholder: "Enter XRF Single Unit Testing Rate",
    },
    {
      name: "xrf_volume_discount_rate",
      label: "XRF Volume Discount Rate",
      type: "number",

      placeholder: "Enter XRF Volume Discount Rate",
    },
  ];

  const mailingFields = [
    {
      name: "street_address",
      label: "Street Address",
      type: "text",
      grid: 12,
      placeholder: "Enter Street Address",
    },
    {
      name: "address",
      label: "Address Line 2",
      type: "text",
      grid: 12,
      placeholder: "Enter Address Line 2",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      grid: 12,
      placeholder: "Enter City",
    },
    {
      name: "state",
      label: "State / Region / Province",
      type: "text",
      grid: 7,
      placeholder: "Enter State",
    },
    {
      name: "zip_code",
      label: "Zip Code",
      type: "text",
      grid: 5,
      placeholder: "Enter Zip Code",
    },
  ];

  const options = [
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={PendingIcon} className="mr-2" />
          Pending Approval
        </div>
      ),
      value: "Pending Approval",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={ActiveIcon} className="mr-2" />
          Active
        </div>
      ),
      value: "Active",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={SchedulingIcon} className="mr-2" />
          Scheduling Blocked
        </div>
      ),
      value: "Scheduling Blocked",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={AccountBlockedIcon} className="mr-2" />
          Account Locked
        </div>
      ),
      value: "Account Locked",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={deleteAccount} className="mr-2" />
          Mark for deletion
        </div>
      ),
      value: "Deleted",
    },
  ];

  const toggleFields = [
    {
      name: "is_approved",
      label: "Approve",
    },
    {
      name: "is_completely_blocked",
      label: "Block Account",
    },
    {
      name: "is_scheduling_blocked",
      label: "Block Scheduling",
    },
  ];

  const submit = (data) => {
    setLoading(true);
    const prepareDate = isEdit
      ? {
          data: {
            email: data.email,
            name: data.name,
            position: data.position,
            phone_number: deFormatPhoneNumber(data.phone_number),
            company_name: data.company_name,
            status: data.status,
            password: data.password,
            zip_code: data.zip_code,
            state: data.state,
            city: data.city,
            address: data.address,
            street_address: data.street_address,
            xrf_single_unit_testing_rate:
              data?.xrf_single_unit_testing_rate || null,
            xrf_volume_discount_rate: data?.xrf_volume_discount_rate || null,
          },
          id: initialValues.id,
        }
      : {
          email: data.email,
          password: data.password,
          name: data.name,
          position: data.position,
          phone_number: deFormatPhoneNumber(data.phone_number),
          company_name: data.company_name,
          is_approved: true,
          zip_code: data.zip_code,
          state: data.state,
          city: data.city,
          address: data.address,
          street_address: data.street_address,
          xrf_single_unit_testing_rate:
            data?.xrf_single_unit_testing_rate || null,
          xrf_volume_discount_rate: data?.xrf_volume_discount_rate || null,
        };
    // console.log("inspector form fields", prepareDate);
    mutate(prepareDate);
  };

  const changeStatus = (status) => {
    setValue("has_permission", status);
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <TextField
              label={"Entity / Management Company"}
              fullWidth
              {...register("company_name", {
                required: `${"Entity / Management Company"} is required`,
              })}
              InputLabelProps={{ shrink: true }}
              error={!!errors["company_name"]}
              helperText={errors["company_name"]?.message}
              // disabled={field.disabled || false}
              placeholder={"Enter name"}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#16304f", // Change this to your desired focus color
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#16304f", // Change this to your desired label focus color
                    fontFamily: "segoe_uisemibold",
                    fontSize: "13px",
                  },
                },
              }}
            />
          </Grid>
          {isEdit ? (
            <Grid item xs={6} md={3}>
              <p className="status-text">Status</p>
              <div className="table-actions">
                <StatusDropDown
                  options={options}
                  // label="Filter by Days"
                  onSelectChange={(value) => setValue("status", value)}
                  defaultValue={initialValues.status || "Pending Approval"}
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <div className="p-24-0">
          <p className="schedule-appointment-heading">
            Contact Person<span className="required-star">*</span>
          </p>
        </div>
        <Grid container spacing={4}>
          {fields.map((field, index) => (
            <Grid item xs={12} md={6} key={index}>
              {field.type === "password" ? (
                <TextField
                  label={field.label}
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  {...register(field.name, {
                    ...(isEdit
                      ? {}
                      : { required: `${field.label} is required` }),
                    ...(field.name == "confirm_password"
                      ? {
                          validate: (value) =>
                            value == password || "Passwords do not match",
                        }
                      : {}),
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              ) : field.type === "number" ? (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  onChange={(e) => {
                    setValue("phone_number", formatPhoneNumber(e.target.value));
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              ) : (
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                    ...(field.name == "confirm_password"
                      ? {
                          validate: (value) =>
                            value === password || "Passwords do not match",
                        }
                      : {}),
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              )}
            </Grid>
          ))}
          {/* {isEdit ? (
            <>
              <Grid item xs={3} sm={2} md={2}>
                <p className="status-text">Status</p>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Switch
                  callback={changeStatus}
                  defaultValue={initialValues.has_permission}
                />
              </Grid>
            </>
          ) : (
            ""
          )} */}
        </Grid>
        <div className="p-24-0">
          <p className="schedule-appointment-heading">
            Mailing Address<span className="required-star">*</span>
          </p>
        </div>
        <Grid container spacing={4}>
          {mailingFields.map((field, index) => (
            <Grid item xs={12} md={6} key={index}>
              {
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name, {
                    required: `${field.label} is required`,
                  })}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              }
            </Grid>
          ))}
        </Grid>
        <div className="p-24-0">
          <p className="schedule-appointment-heading">Pricing</p>
          <p className="form-instruction">
            Pricing valid on Studio, 1 & 2 bedroom MAX 5 rooms, additional rooms
            incure additional $25 per room. Volume discount only applies to 5+
            units tested at the same building on the same day.
          </p>
        </div>
        <Grid container spacing={4}>
          {pricingField.map((field, index) => (
            <Grid item xs={12} md={6} key={index}>
              {
                <TextField
                  label={field.label}
                  fullWidth
                  {...register(field.name)}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  error={!!errors[field.name]}
                  inputProps={{
                    step: "any", // Allows decimals
                  }}
                  helperText={errors[field.name]?.message}
                  disabled={field.disabled || false}
                  placeholder={field.placeholder}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#16304f", // Change this to your desired focus color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#16304f", // Change this to your desired label focus color
                        fontFamily: "segoe_uisemibold",
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              }
            </Grid>
          ))}
        </Grid>
        <Stack
          sx={{
            alignSelf: "end",
          }}
          direction={"row"}
          spacing={2}
          className="popup-footer-border"
        >
          <Button
            variant="outlined"
            className="secondary-btn-class"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={loading ? "capitalize h-42" : "primary-btn-class"}
            disabled={loading}
            sx={{ boxShadow: "none" }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
      {/* </Grid> */}
    </form>
  );
}

export default ClientForm;
