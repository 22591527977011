import React, { useEffect, useState } from "react";
import { useClient } from "../../../../reactQuery/client";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import CustomSelectDropdown from "./DropDownFilter";

function Client({ complianceType }) {
  const [client, setClient, remove] = useCustomSearchParams("client");
  const [selectedValue, setSelectedValue] = useState(client);
  const {
    isLoading,
    error,
    responseData,
    isError,
    refetch,
    totalRecords,
    isRefetching,
  } = useClient({
    page: 1,
    pagination: false,
    sort: `&sort_by=asc&sort=company_name`,
    compliance: `&${complianceType}=true`,
  });

  useEffect(() => {
    refetch()
  }, [complianceType]);

  useEffect(() => {
    if (!client) {
      setSelectedValue("");
    }
    remove("building");
  }, [client]);

  return (
    <div className="input-select-box mr-2">
      <CustomSelectDropdown
        defaultValue={client}
        isSearch={true}
        options={
          responseData?.map((el) => {
            return {
              label: el.company_name,
              value: el.client_id,
            };
          }) || []
        }
        label={"Clients"}
        selectedValue={selectedValue}
        onSelectChange={(value) => {
          setSelectedValue(value);
          setClient("client", value);
        }}
      />
    </div>
  );
}

export default Client;
