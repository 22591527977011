import React, { useEffect, useState } from "react";
import ComplianceYear from "./ComplianceYear";
import MailerSend from "./MailerSend";
import EnterSurveyResponse from "./EnterSurveyResponse";
import MailerToSend from "./Download/MailerToSend";
import MailerForReview from "./Download/MailerForReview";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import RightFilters from "../Filters/RightFilters";
import { downloadPendingMailer } from "../../../../sevices/leadComplianceApi";

function Header() {
  const [year, setYear] = useCustomSearchParams("year");
  const currentYear = new Date().getFullYear();
  const [isPastYear, setPastYear] = useState(false);

  useEffect(() => {
    setPastYear(currentYear > year);
  }, [year]);

  return (
    <div className="d-flex justify-content-space-between">
      <div className="d-flex">
        <ComplianceYear name={"Compliance Year"} />
        {isPastYear || <EnterSurveyResponse />}
      </div>
      {/* <div>
        <MailerSend />
      </div> */}
      {isPastYear ? (
        ""
      ) : (
        <div className="d-flex">
          <RightFilters complianceType={"mailer_program"} />
          <MailerToSend
            btnTitle={"Mailers to Send"}
            downloadCallback={downloadPendingMailer}
            pdfName={"annual-report-mailer-to-send.pdf"}
          />
          <MailerForReview
            btnTitle={"Mailers for Review"}
            downloadCallback={downloadPendingMailer}
            pdfName={"annual-report-mailer-for-review.pdf"}
          />
        </div>
      )}
    </div>
  );
}

export default Header;
