import React, { useState } from "react";
import ViewSurveyIcon from "../../../../../styles/svg/view-docs.svg";
import LongFormDialog from "../../../../../components/Dialog/LongFormDialog";
import EnterSurveyForm from "../../../../LeadCompliance/components/Header/EnterSurveyForm";
import DownloadSurveyCompliance from "../../../../LeadCompliance/components/Table/DownloadSurveyCompliance";
import SurveyIcon from "../../../../../styles/svg/survey-download.svg";
import ViewFile from "../../../../../components/ViewFile";
import { downloadSurveyForm } from "../../../../../sevices/leadComplianceApi";
import toast from "react-hot-toast";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";

function ViewSurvey({ params }) {
  const [anchorEl, setAnchorEl] = useState(false);
    const [url, setUrl] = useState("");
    const [year] = useCustomSearchParams("year");

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  const downloadMailer = () => {
    // setLoading(true);
    downloadSurveyForm({
      year: year,
      compliance_id: params.row?.compliance?.compliance_id,
    })
      .then((res) => {
        setUrl({
          url: `/pdf/${res.data.pdf_file_path}`,
        });
        handleClick();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  
  // const data = params.row?.compliance?.filter(
  //   (el) => el.compliance_year == new Date().getFullYear()
  // )[0];
  return (
    <>
      {anchorEl && (
        <LongFormDialog
          open={anchorEl}
          handleClose={handleClose}
          title={"Survey Response"}
          btnTitle={"Confirm"}
          IsChildren={
            <DownloadSurveyCompliance
              complianceId={params.row?.compliance?.compliance_id}
            />
          }
        >
          <ViewFile url={url.url} />
        </LongFormDialog>
      )}
      <div className="w-100 d-flex justify-content-center">
        {params.row?.compliance?.status != "pending" &&
          params.row?.compliance?.status && (
            <img
              src={SurveyIcon}
              onClick={downloadMailer}
              className="cursor-pointer"
            />
          )}
      </div>
    </>
  );
}

export default ViewSurvey;
