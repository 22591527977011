import React from 'react'
import { useParams } from 'react-router-dom';

import ClientDetails from "../ClientDetails";
import Heading from '../../../../../components/Heading';
import ComplianceYear from '../../../../LeadCompliance/components/Header/ComplianceYear';
import Building from '../../../../LeadCompliance/components/Filters/Building';
import Table from "../../../../LeadCompliance/components/Table";
import Filters from "../../../../LeadCompliance/components/Filters";
import { useCustomSearchParams } from '../../../../../hooks/useCustomSearchParams';

function ClientCompliance() {
    const params = useParams();
      const [building_id] = useCustomSearchParams("building");
  return (
    <div>
      {params?.clientId && <ClientDetails />}
      <Heading title="Annual Compliance">
        <ComplianceYear name="Compliance Year" />
        <Building
          clientId={params?.clientId}
          complianceType={"mailer_program"}
        />
      </Heading>
      <div className="mt-4 ticket-page">
        <Filters building={building_id} client={params?.clientId} />
        <Table clientId={params?.clientId} />
      </div>
    </div>
  );
}

export default ClientCompliance