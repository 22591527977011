import React from 'react'
import { useParams } from 'react-router-dom';

import ClientDetails from "../ClientDetails";
import Heading from '../../../../../components/Heading';
import ComplianceYear from '../../../../LeadCompliance/components/Header/ComplianceYear';
import Building from '../../../../LeadCompliance/components/Filters/Building';
import { useCustomSearchParams } from '../../../../../hooks/useCustomSearchParams';
import Filters from '../../../../FireNotice/filters.js';
import Table from '../../../../FireNotice/table/index.js';

function FireNoticeCompliance() {
    const params = useParams();
      const [building_id] = useCustomSearchParams("building");
  return (
    <div>
      {params?.clientId && <ClientDetails />}
      <Heading title="Fire Notice">
        <ComplianceYear name="Compliance Year" />
        <Building clientId={params?.clientId} complianceType={"fire_notice"} />
      </Heading>
      <div className="mt-4 ticket-page">
        <Filters building={building_id} client={params?.clientId} />
        <Table clientId={params?.clientId} />
      </div>
    </div>
  );
}

export default FireNoticeCompliance