import React, { useState } from "react";
import PendingIcon from "../../../styles/svg/pending-icon.svg";
import ActiveIcon from "../../../styles/svg/active-icon.svg";
import SchedulingIcon from "../../../styles/svg/scheduling-blocked-icon.svg";
import AccountBlockedIcon from "../../../styles/svg/account-blocked-icon.svg";
import deleteAccount from "../../../styles/svg/delete-client.svg";
import ChangeStatus from "./ChangeStatus";
import { useCustomParams } from "../../../hooks/useCustomParams";
import CustomSelectDropdown from "./DropDownFilter";

function StatusFilter() {
  const [status, setStatus, removeStatus] = useCustomParams("status");
  const [selectedValue, setSelectedValue] = useState(status);
  const options = [
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={PendingIcon} className="mr-2" />
          Pending Approval
        </div>
      ),
      value: "Pending Approval",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={ActiveIcon} className="mr-2" />
          Active
        </div>
      ),
      value: "Active",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={SchedulingIcon} className="mr-2" />
          Scheduling Blocked
        </div>
      ),
      value: "Scheduling Blocked",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={AccountBlockedIcon} className="mr-2" />
          Account Locked
        </div>
      ),
      value: "Account Locked",
    },
    {
      label: (
        <div className="d-flex align-item-center text-status">
          <img src={deleteAccount} className="mr-2" />
          Mark for deletion
        </div>
      ),
      value: "Deleted",
    },
  ];
  const changeStatus = (value) => {
    // const data = { id: params.row.client_id, data: { status: value } };
    // mutate(data);
    setStatus("status", value);
  };
  const removeFilter = () => {
    removeStatus("status");
    setSelectedValue("");
  };
  return (
    <div className="table-actions d-flex align-items-center">
      <div>
        {status ? (
          <p className="clear-filter" onClick={removeFilter}>
            Clear Filter
          </p>
        ) : (
          ""
        )}
      </div>
      {/* {" "}
      <ChangeStatus
        options={options}
        label="Filter by Status"
        onSelectChange={changeStatus}
        // defaultValue={params.row.status}
      /> */}
      <CustomSelectDropdown
        defaultValue={status || ""}
        options={options}
        label={"Filter by Status"}
        selectedValue={selectedValue || ""}
        onSelectChange={(value) => {
          setSelectedValue(value);
          setStatus("status", value);
        }}
      />
    </div>
  );
}

export default StatusFilter;
