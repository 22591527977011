import React, { useState } from "react";
import useDownloader from "react-use-downloader";
import toast from "react-hot-toast";

import PrimaryButton from "../../../components/Button/Button";
import { Button, CircularProgress } from "@mui/material";
import { downloadFireSurvey } from "../../../sevices/fireNoticeApi";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";

function DownloadSurveyCompliance({ surveyId }) {
  const [loading, setLoading] = useState(false);
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();
    const [year] = useCustomSearchParams("year");

  const downloadMailer = () => {
    setLoading(true);
    downloadFireSurvey({
      year: year,
      fire_notice_id: surveyId,
    })
      .then((res) => {
        download(
          `${process.env.REACT_APP_BASE_URL}/pdf/${res.data.pdf_file_path}`,
          `survey-${surveyId}.pdf`
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <PrimaryButton
        variant="contained"
        className="capitalize btn-bg-orange popup-header-button"
        onClick={downloadMailer}
        disabled={loading}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : null
        }
      >
        {loading ? "Downloading..." : " Download Survey"}
      </PrimaryButton>
    </div>
  );
}

export default DownloadSurveyCompliance;
