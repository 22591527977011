import React from "react";
import { useCustomParams } from "../../../../hooks/useCustomParams";
import { useRemoveAllFilters } from "../../../../hooks/useRemoveAllFilters";
import Client from "./Client";
import Building from "./Building";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import Download from "./Download";

function RightFilters({ complianceType }) {
  const [removeAll] = useRemoveAllFilters();
  const [status, updateStatus] = useCustomParams("status");
  const [building, setBuilding] = useCustomSearchParams("building");
  const [client, setClient] = useCustomSearchParams("client");
  const removeFilter = () => {
    removeAll(["status", "building", "client"]);
  };
  return (
    <div className="d-flex align-items-center mr-2">
      <div>
        {status || building || client ? (
          <p className="clear-filter" onClick={removeFilter}>
            Clear Filter
          </p>
        ) : (
          ""
        )}
      </div>
      <Client complianceType={complianceType} />
      <Building complianceType={complianceType} />
      {/* <Download /> */}
    </div>
  );
}

export default RightFilters;
