import React from "react";
import { useCustomParams } from "../../../../hooks/useCustomParams";
import { useRemoveAllFilters } from "../../../../hooks/useRemoveAllFilters";
import { useMediaQuery } from "react-responsive";
import CustomSelectDropdown from "../../../../components/CustomSelectDropDown";
import PrimaryButton from "../../../../components/Button/Button";
import { useSelector } from "react-redux";

function LeftFilters() {
  const [status, updateStatus] = useCustomParams("status");
  const statusCount = useSelector((state) => state.compliance.count);
  const isMobile = useMediaQuery({ maxWidth: "690px" });
  const buttons = [
    { label: `Total ${statusCount?.total || ""}`, value: "" },
    { label: `No Action ${statusCount?.completed || ""}`, value: "completed" },
    { label: `Pending ${statusCount?.pending || ""}`, value: "pending" },
    {
      label: `Inspection Needed ${statusCount?.inspection_needed || ""}`,
      value: "inspection_needed",
    },
    {
      label: `Window Guards ${statusCount?.windows_guards_required || ""}`,
      value: "windows_guards_required",
    },
    {
      label: `Knob Covers ${statusCount?.knob_covers_required || ""}`,
      value: "knob_covers_required",
    },
    // {
    //   label: `Inspection Scheduled ${statusCount?.inspection_scheduled || ""}`,
    //   value: "inspection_scheduled",
    // },
  ];

  const buttons2 = [
    { label: "Total", value: "", total: statusCount?.total || "" },
    {
      label: "No Action",
      value: "completed",
      total: statusCount?.completed || "",
    },
    { label: "Pending", value: "pending", total: statusCount?.pending || "" },
    {
      label: "Inspection Needed",
      value: "inspection_needed",
      total: statusCount?.inspection_needed || "",
    },
    {
      label: "Window Guards",
      value: "windows_guards_required",
      total: statusCount?.windows_guards_required || "",
    },
    {
      label: "Knob Covers",
      value: "knob_covers_required",
      total: statusCount?.knob_covers_required || "",
    },
    // {
    //   label: "Inspection Scheduled",
    //   value: "inspection_scheduled",
    //   total: statusCount?.inspection_scheduled || "",
    // },
  ];

  return (
    <div>
      {isMobile ? (
        <CustomSelectDropdown
          options={buttons}
          label="Filter by Days"
          onSelectChange={(value) => updateStatus("date", value)}
          defaultValue={status}
        />
      ) : (
        buttons2.map((el, idx) => {
          return (
            <PrimaryButton
              variant="contained"
              key={idx}
              className={`capitalize ${
                el.value == status ? "btn-bg-grey" : "btn-bg-light-grey"
              } `}
              onClick={() => updateStatus("status", el.value)}
            >
              {el.label}{" "}
              <span
                className={`${
                  el.value == status ? "survey-count-select" : "survey-count"
                } `}
              >{` ${el.total}`}</span>
            </PrimaryButton>
          );
        })
      )}
    </div>
  );
}

export default LeftFilters;
