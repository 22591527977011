import React, { useEffect, useState } from "react";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useClientBuildings } from "../../../../reactQuery/building";
import CustomSelectDropdown from "./DropDownFilter";

function Building({ clientId, complianceType }) {
  const [building, setBuilding] = useCustomSearchParams("building");
  const [client, setClient] = useCustomSearchParams("client");
  const [selectedValue, setSelectedValue] = useState(building);

  const { isLoading, error, responseData, isError, refetch, totalRecords } =
    useClientBuildings(
      {
        page: 1,
        pageSize: 1,
        clientId: clientId ? clientId : client,
        pagination: false,
        compliance: `&${complianceType}=true`,
      },
      clientId ? clientId : client
    );

  useEffect(() => {
    refetch();
  }, [client, clientId, complianceType]);

  useEffect(() => {
    if (!client) {
      setSelectedValue("");
    } else if (!building) {
      setSelectedValue("");
    }
  }, [client, building]);
  return (
    <div className="input-select-box">
      <CustomSelectDropdown
        defaultValue={building}
        isSearch={true}
        options={
          responseData?.map((el) => {
            return {
              label: el.address,
              value: el.building_id,
            };
          }) || []
        }
        label={"Building"}
        selectedValue={selectedValue}
        onSelectChange={(value) => {
          setSelectedValue(value);
          setClient("building", value);
        }}
        disabled={client || clientId ? false : true}
      />
    </div>
  );
}

export default Building;
